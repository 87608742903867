import type { ReactNode } from "react";
import { css } from "styled-system/css";

export const LoginOuter = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      className={css({
        alignItems: "center",
        bg: "gray.100",
        display: "flex",
        flexDirection: "column",
        height: "100dvh",
        justifyContent: "center",
        width: "100dvw",
      })}
    >
      {children}
    </div>
  );
};

export const LoginInner = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div
      className={css({
        bg: "white",
        borderRadius: 8,
        padding: 8,
        width: 400,
      })}
    >
      {children}
    </div>
  );
};

export const LoginTitle = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <h1
      className={css({
        fontSize: "2xl",
        fontWeight: "bold",
        mb: 8,
      })}
    >
      {children}
    </h1>
  );
};
