import { Outlet } from "@remix-run/react";
import { LoginInner, LoginOuter, LoginTitle } from "~/components/routes/_auth";

export default function Layout() {
  return (
    <LoginOuter>
      <LoginInner>
        <LoginTitle>契約管理システム</LoginTitle>
        <Outlet />
      </LoginInner>
    </LoginOuter>
  );
}
